import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Component {...props} />
    </Suspense>
  );
};

function AppRoutes() {
  const routes = useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        { element: <Home replace />, index: true },
        { path: "myprofile", element: <MyProfile /> },
        { path: "about", element: <About /> },
        { path: "services", element: <Services /> },
        { path: "servicedetail/:id", element: <ServiceDetail /> },
        { path: "blog", element: <Blog /> },
        { path: "faq", element: <Faq /> },
        // { path: "services/:users", element: <ServiceDetail /> },
        { path: "support", element: <Support /> },
        { path: "events", element: <Events /> },
        { path: "bespokeservice", element: <BespokeServices /> },
        { path: "businesssolution", element: <BusinessSolution /> },
        { path: "b2bservice", element: <B2BServices /> },
        {
          path: "logout",
          element: <Logout />,
        },
      ],
    },
  ]);

  return routes;
}


export default function RouterComponent() {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

// components
const Layout = Loadable(lazy(() => import("../Components/Layout/Layout")));
const Home = Loadable(lazy(() => import("../Components/Home/Home")));
const MyProfile = Loadable(lazy(() => import("../Components/profile/MyProfile")));
const About = Loadable(lazy(() => import("../Components/Company/About")));
const Blog = Loadable(lazy(() => import("../Components/Company/Blog")));
const Faq = Loadable(lazy(() => import("../Components/Company/Faq")));
const Services = Loadable(lazy(() => import("../Components/Company/Services")));
const ServiceDetail = Loadable(lazy(() => import("../Components/Company/ServiceDetail")));
const Support = Loadable(lazy(() => import("../Components/Company/Support")));
const Events = Loadable(lazy(() => import("../Components/Events/EventTabs")));
const BespokeServices = Loadable(
  lazy(() => import("../Components/bespokeServices/BespokeServices"))
);
const BusinessSolution = Loadable(
  lazy(() => import("../Components/businessSolution/BusinessSolution"))
);
const B2BServices = Loadable(lazy(() => import("../Components/b2bSevices/B2BServices")));
const Logout = Loadable(lazy(() => import("../Components/profile/Logout")));
