import "./App.css";

import RouterComponent from "./routes/Routes";
import Notistack from "./Components/third-party/Notistack";

function App() {
  return (
    <>
      <Notistack>
        <RouterComponent />
      </Notistack>
    </>
  );
}

export default App;
